import styled from "styled-components";

import { FaTimes } from "react-icons/fa";
import { blackLight } from "../../Theme";

export const GalleryContainer = styled.div`
  position: relative;
`;

export const GalleryWrapper = styled.div`
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;

  padding: 0 12px;
  background-color: ${blackLight};

  @media screen and (max-width: 1366px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }

  @media screen and (max-width: 768px) {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
`;

export const Pictures = styled.div`
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;

  &:hover {
    filter: opacity(0.8);
  }
`;

export const Model = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s;
  visibility: ${({ model }) => (model ? "visible" : "hidden")};
  opacity: ${({ model }) => (model ? 1 : 0)};
  transform: scale(${({ model }) => (model ? 1 : 0)});
  overflow: hidden;
  z-index: 999;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }
`;

export const CloseIcon = styled(FaTimes)`
  position: fixed;
  top: 10px;
  right: 10px;
  width: 4rem;
  height: 4rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
`;
