import styled from "styled-components";
import { red, white } from "../../Theme";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #010606;
  padding-bottom: 20px;

  h2 {
    position: relative;
    text-align: center;
    color: ${white};
    margin-bottom: 16px;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }

    /*underline*/

    &:after {
      bottom: 0;
      content: "";
      display: block;
      margin-bottom: -10px;
      height: 2px;
      position: absolute;
      background: ${red};
      width: 90%;
      left: 5%;
    }
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;
export const AboutWrapper = styled.div`
  display: flex;
  max-width: 1600px;
  margin: 40px 0 0 40px;
`;

export const AboutSectionP = styled.p`
  color: ${white};
  flex: 2;
  padding: 0 10px 0 0;
`;

export const FacebookSection = styled.div`
  flex: 1;
  margin-right: 40px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
