import React, { useState } from "react";
import {
  AthleteCards,
  AthletePhoto,
  FacebookIcon,
  FirstName,
  ImgWrapper,
  InstagramIcon,
  LastName,
  NameWrapperH3,
  Nickname,
  SocialWrapper,
  SportName,
  SportWrapper,
} from "./styledAthletes";
import Link from "../GlobalLink/Link";
import Popup from "../Popup/Popup";

const Athletes = ({ athlete }) => {
  const [imgHover, setImgHover] = useState(false);

  const onHover = () => {
    setImgHover(!imgHover);
  };

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <AthleteCards onClick={() => setIsOpenPopup(true)}>
      {isOpenPopup && (
        <Popup setIsOpenPopup={setIsOpenPopup} data={athlete.popup} />
      )}
      <NameWrapperH3>
        <FirstName>{athlete.firstName}</FirstName>
        <Nickname>{athlete.nickname}</Nickname>
        <LastName>{athlete.lastName}</LastName>
      </NameWrapperH3>
      <SportWrapper>
        <SportName>{athlete.sport}</SportName>
        <SportName>{athlete.secondLineSport}</SportName>
      </SportWrapper>
      <SocialWrapper>
        <Link href={athlete.facebook}>
          <FacebookIcon />
        </Link>
        <Link href={athlete.instagram}>
          <InstagramIcon />
        </Link>
      </SocialWrapper>
      <ImgWrapper>
        <AthletePhoto src={athlete.photo} />
      </ImgWrapper>
      {/*<ButtonWrapper>*/}
      {/*  <Button onClick={() => setIsOpenPopup(true)}>{athlete.button}</Button>*/}
      {/*  {isOpenPopup &&*/}
      {/*    <Popup setIsOpenPopup={setIsOpenPopup} />}*/}
      {/*</ButtonWrapper>*/}
    </AthleteCards>
  );
};

export default Athletes;
