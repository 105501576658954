import React from 'react';
import './timeTableStyles.css';
import { Wrapper } from './styledTimeTable';

const TimeTable = () => {
  return (
    <Wrapper id='timeTable'>
      <div className='grid'>
        <div className='span-col-4 tableTitle'>UNYP ARENA</div>
        <div className='span-col-4 tableTitle'>Vault Gym</div>
        <div>Pondělí</div>
        <div></div>
        <div>
          16:30-17:30 <br /> Bojová <br /> průprava dětí <br /> (Talačová)
        </div>
        <div>
          17:30-18:30 <br /> Sparing MMA otevřené <br /> (Vyvážil)
        </div>
        <div></div>
        <div>Pondělí</div>
        <div className='span-col-2'>
          18:00-19:30 <br />
          MMA B <br />
          Klouda, Hrdlička
        </div>
        <div>Úterý</div>
        <div></div>
        <div>
          16:30-17:30 <br /> Bojová <br /> průprava dětí <br /> (Horák)
        </div>
        <div>
          17:30-19:00 <br /> MMA A <br /> (Vyvážil, Čedík)
        </div>
        <div>
          19:00-20:30 <br /> MMA B <br /> (Vyvážil, Čedík)
        </div>
        <div>Úterý</div>
        <div></div>
        <div></div>
        <div>Středa</div>
        <div></div>
        <div>16:30-17:30 <br /> Bojová <br /> průprava dětí <br /> (Talačová)</div>
        <div>
        </div>
        <div></div>
        <div>Středa</div>
        <div className='span-col-2'>
          18:00-19:30 <br />
          MMA B <br />
          Klouda, Hrdlička
        </div>
        <div>Čtvrtek</div>
        <div></div>
        <div>
          16:30-17:30 <br /> Bojová <br /> průprava dětí <br /> (Horák)
        </div>
        <div>
          17:30-19:00 <br /> MMA A <br /> (Vyvážil, Čedík)
        </div>
        <div>
          19:00-20:30 <br /> MMA B <br /> (Vyvážil, Čedík)
        </div>
        <div>Čtvrtek</div>
        <div></div>
        <div></div>
        <div>Pátek</div>
        <div></div>
        <div></div>
        <div className='span-col-2'></div>
        <div>Pátek</div>
        <div className='span-col-2'>
          18:00-19:30 <br />
          MMA B <br />
          Klouda, Hrdlička
        </div>
        <div>Sobota</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>Sobota</div>
        <div></div>
        <div></div>
        <div>Neděle</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>Neděle</div>
        <div></div>
        <div></div>
      </div>
    </Wrapper>
  );
};

export default TimeTable;
