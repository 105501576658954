import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Pages/Pages";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <title> Renegade Prague Gym - MMA</title>
      </Helmet>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
