import styled from "styled-components";

import UnypArena from "../../images/rpgmma1.JPEG";
import VaultGym from "../../images/rpgmma2.JPEG";
import { red } from "../../Theme";

export const PanoramaContainerUnyp = styled.div`
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-image: url(${UnypArena});
`;

export const PanoramaContainerVault = styled.div`
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-image: url(${VaultGym});
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/opacity/see-through */
  color: white;
  font-size: 5rem;
  font-weight: bold;
  position: relative;
  z-index: 2;

  width: 40%;
  left: 50%;
  height: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    0px 0px 50px 5px #000000;
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    0px 0px 50px 5px #000000;

  :after {
    content: "";
    position: absolute;
    filter: blur(8px);
  }

  @media screen and (max-width: 1366px) {
    width: 50vw;
    h2 {
      font-size: 4rem;
    }
    h3 {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 65vw;
  }

  @media screen and (max-width: 768px) {
    width: 90vw;
    h2 {
      font-size: 2.8rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
`;

export const Title = styled.h2`
  font-size: 4rem;
`;

export const SubTitle = styled.h3`
  font-size: 3rem;
  color: ${red};
`;
