import {
  CloseBtn,
  Container,
  Content,
  Header,
  HeaderH3,
  InfoP,
  LeftContent,
  Row,
  Column,
  Row2,
  HeaderH4,
  TapologySherdog,
} from "./styledPopup";

import Link from "../GlobalLink/Link";

import TapologyIMG from "../../images/Tapology-Logo-1-1.jpg";
import SherdogIMG from "../../images/Sherdog-Logo-1-1.jpg";

const Popup = ({ setIsOpenPopup, data }) => {
  return (
    <Container onClick={() => setIsOpenPopup(false)}>
      <Content onClick={(e) => e.stopPropagation()}>
        <CloseBtn onClick={() => setIsOpenPopup(false)}></CloseBtn>
        <Row>
          <Column>
            <LeftContent src={data.photo}></LeftContent>
          </Column>
          <Column>
            <Header>
              <HeaderH3>{data.heading}</HeaderH3>
              <HeaderH4>{data.nickname}</HeaderH4>
            </Header>
            <InfoP>{data.paragraph1}</InfoP>
            <InfoP>{data.paragraph2}</InfoP>
            <InfoP>{data.paragraph3}</InfoP>
            <InfoP>{data.paragraph4}</InfoP>
            <InfoP>{data.paragraph5}</InfoP>
            <InfoP>{data.paragraph6}</InfoP>
          </Column>
        </Row>
        <Row2>
          <Column>
            <TapologySherdog>
              <Link
                href={data.tapologyLink}
                img
                src={TapologyIMG}
                justifySelf={"right"}
              />
            </TapologySherdog>
          </Column>
          <Column>
            <TapologySherdog>
              <Link
                href={data.sherdogLink}
                img
                src={SherdogIMG}
                justifySelf={"right"}
              />
            </TapologySherdog>
          </Column>
        </Row2>
      </Content>
    </Container>
  );
};
export default Popup;
