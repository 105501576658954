import React from 'react';
import { HeadingH2, HeadingH3 } from '../../Theme/Headings';
import { MdLocalPostOffice } from 'react-icons/md';
import {
  Container,
  Info,
  Email,
  MapLocation,
  MapSterboholy,
  MapModrany,
  Address,
  ModranyWrapper,
  Wrapper,
} from './styledContact';

const Contact = () => {
  return (
    <Container id='contact'>
      <HeadingH2>Kontakt</HeadingH2>
      <Info>
        <MdLocalPostOffice fontSize='2em' />
        <Email>rpgmma@gmail.com</Email>
      </Info>
      <MapLocation>
        <Wrapper>
          <HeadingH3>UNYP ARENA</HeadingH3>
          <Address>Kovanecká 27, 190 00 Praha 9-Libeň</Address>
          <MapSterboholy>
            <iframe
              title='UNYP ARENA mapa'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d904.682735432827!2d14.486166508307624!3d50.10820407294931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470becad8e8ff6bb%3A0x63fb18dc93798d1d!2sUNYP%20ARENA!5e0!3m2!1sen!2scz!4v1682603909565!5m2!1sen!2scz'></iframe>
          </MapSterboholy>
        </Wrapper>
        <Wrapper>
          <HeadingH3>Vault Gym</HeadingH3>
          <Address>Obchodní nám. 1590/4, 143 00 Praha 12</Address>
          <MapModrany>
            <iframe
              title='VAULT GYM mapa'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d762.379909884351!2d14.405034085215538!3d50.00516718597242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b96c8d89232ed%3A0x5659cba7a5573973!2sVault%20Gym!5e0!3m2!1sen!2scz!4v1661637067441!5m2!1sen!2scz'></iframe>
          </MapModrany>
        </Wrapper>
      </MapLocation>
    </Container>
  );
};

export default Contact;
