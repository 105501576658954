import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { thirdHeading } from "../../Theme";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Content = styled.div`
  position: absolute;
  background: white;
  border-radius: 8px;
  width: 850px;
  padding: 20px 10px;

  @media screen and (max-width: 768px) {
    width: 80%;
    height: 65vh;
  }

  animation: dropTop 0.3s linear;

  @keyframes dropTop {
    0% {
      transform: translateY(-100%) scale(0);
      opacity: 0;
    }
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }
  }
`;

export const LeftContent = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const RightContent = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  border-bottom: 1px solid lightgray;
  font-size: ${thirdHeading};
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const HeaderH3 = styled.h3`
  top: 20px;
`;

export const HeaderH4 = styled.h3`
  top: 20px;
`;

export const CloseBtn = styled(FaTimes)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;

  @media screen and (max-width: 678px) {
    top: 5px;
    right: 5px;
    font-size: 1.5rem;
  }
`;

export const InfoP = styled.p`
  padding: 12px 0;
`;

export const FooterP = styled.p`
  border-top: 1px solid lightgray;
  padding-top: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const TapologySherdog = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid lightgray;

  padding: 20px 0;

  img {
    width: 100%;
    transform: scale(0.9);
  }

  img:hover {
    transform: scale(0.95);
    transition: transform 0.25s ease;
  }

  @media screen and (max-width: 678px) {
    padding: 10px 0;
    img {
      width: 180px;
      height: auto;
    }
  }
`;
