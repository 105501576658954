import React from "react";
import Coaches from "./Coaches";
import { CoeachesContainer, ContainerWrapper } from "../styledCoaches";

import Vyvazil from "../../../images/David Vyvážil.png";
import Horak from "../../../images/Jan-Horak.png";
import Cedik from "../../../images/Roman Čedík.png";
import Kimmel from "../../../images/Erik Kimmel.png";
import PanoramaDaily from "../../Panorama/panoramaDaily";

const dataCoaches = () => {
  const coaches = [
    {
      id: 2,
      name: "David Vyvážil",
      role: "Head Coaches",
      role2: "",
      photo: Vyvazil,
      paragraph1:
        "Třicet let v bojových sportech. Prošel judem, zápasem, boxem, thaiboxem, kickboxem, sambem a Brazilským jiu-jitsu.",
      paragraph2:
        "Jeden z prvních zápasníků v bojích bez pravidel (nyní MMA) a zakladatel gymů v Týništi nad Orlicí, Dvoře Králové nad Labem, Hradci Králové a Praze",
      paragraph3:
        "První zápas v MMA absolvoval v roce 1999. Ukončil zápasnickou kariéru se skórem 8-3-0. Nyní se věnuje MMA pouze jako trenér.",
      paragraph4: "",
      textPosition: "center",
    },
    {
      id: 4,
      name: "Roman Čedík",
      role: "Grappling",
      role2: "Brazilský Jiu-Jitsu",
      photo: Cedik,
      paragraph1:
        "Vystudoval Vysokou školu tělesné výchovy a sportu Palestra, obor Sportovní a kondiční specialista. Působí 32 let v oblasti bojových umění a sportu.",
      paragraph2:
        "Bývalý reprezentant ČR v Unifightu a Brazilském jiu-jitsu. Získal několik medailových umístění z evropských a světových šampionátů. V roce 2016 získal černý pás v Brazilském jiu-jitsu.",
      paragraph3:
        "Svojí sportovní kariéru ukončil roku 2018. Dnes trenérem Brazilského jiu- jitsu a MMA a je také fitness a kondičním trenérem.",
      textPosition: "center",
    },
    {
      id: 5,
      name: "Ján Horák",
      role: "Dětský oddíl",
      role2: "",
      photo: Horak,
      paragraph1:
        "Přes dvacet let se věnoval kariéře zápasníka ve volném stylu, pak přešel k Brazilskému jiu-jitsu, grapplingu a amatérskému MMA.",
      paragraph2:
        "Je mistrem Evropy v Brazilskému Jiu-Jitsu a grapplingu z let 2015 a 2017, mistrem světa v amatérském MMA z roku 2015, mistrem Polska v grapplingu z roku 2018 a v roce 2019 se stal šampionem organizace NAGA.",
      paragraph3:
        "První licenci jako dětský trenér získal na Slovensku v roce 1997. Dále má od roku 2013 v ČR licenci jako trenér MMA III. třídy, je sportovním instruktorem dětí a mládeže III. třídy od roku 2019 a rozhodčím ČSMMA od roku 2019.",
      paragraph4:
        "Tréninky jsou zaměřené na základy gymnastiky, zápasu, atletiky, juda, boxu, vše hravou formou.",
      textPosition: "center",
    },
    {
      id: 6,
      name: "Erik Kimmel",
      role: "silový a kondiční trenér",
      role2: "",
      photo: Kimmel,
      paragraph1:
        "Získal bakalářský titul v oboru Regenerace a výživa ve sportu FSpS Brno a v roce 2012 odjel studovat na Universitat de Valencia (sportovní studia).",
      paragraph2:
        "Má 11 let trenérské praxe v oblasti výkonnostního sportu (spolupráce s reprezentačními týmy – bojové sporty, cyklistika, atletika)",
      paragraph3:
        "Zaměřuje se na silovou a rychlostní příprava sportovců (VBT training), fyziologický trénink a sportovní testování ve spolupráci s Centrem sportovní medicíny, funkční respirační trénink a dechové manipulace.",
      textPosition: "center",
    },
  ];

  const coachList = coaches.map(
    (coach) => <Coaches coach={coach} hiddenDesc={coach?.hiddenDesc} /> //? - či ta propsa existuje, jinak je to je undefined
  );

  return (
    <CoeachesContainer id="coaches">
      <PanoramaDaily />
      <ContainerWrapper>{coachList}</ContainerWrapper>
    </CoeachesContainer>
  );
};

export default dataCoaches;
