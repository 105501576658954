import styled from "styled-components";
import {
  blackLight,
  fourthHeading,
  red,
  thirdHeading,
  white,
} from "../../Theme/index";

export const CoeachesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${blackLight};
  padding-bottom: 20px;
`;

export const ContainerWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ContainerCard = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  //align-items: center;
  margin-top: 35px;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const CardImg = styled.img`
  max-width: 300px;
  max-height: 375px;
  margin: 0 auto;
  float: left;

  :nth-child(1) {
    max-width: 440px;
    height: auto;
  }
`;

export const CardInfo = styled.div``;

export const TitleWrapper = styled.div`
  text-align: ${(props) => props.setAlign};

  h3 {
    color: ${white};
    font-size: ${thirdHeading};
    margin-bottom: 10px;
    line-height: 1.2;

    &:nth-child(2) {
      color: lightgreen;
    }
  }

  h4 {
    color: ${red};
    font-size: ${fourthHeading};
    margin-bottom: 10px;
  }
`;

export const CardP = styled.div`
  color: ${white};
  text-align: ${(props) => props.setAlign};
`;

export const Paragraph1 = styled.p`
  margin-bottom: 10px;
`;

export const Paragraph2 = styled(Paragraph1)``;

export const Paragraph3 = styled(Paragraph1)``;

export const Paragraph4 = styled(Paragraph1)``;

export const CoachPhone = styled.div`
  font-size: 2rem;
  a {
    color: ${red};
    text-decoration: none;
    &:hover {
      color: ${white};
    }
  }
`;
