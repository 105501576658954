import React from "react";
import {
  PanoramaContainerVault,
  TitleWrapper,
  Title,
  SubTitle,
} from "./styledPanorama";

const PanoramaDaily = () => {
  return (
    <PanoramaContainerVault>
      <TitleWrapper>
        <Title>
          Vault Gym
          <SubTitle>Trenéři</SubTitle>
        </Title>
      </TitleWrapper>
    </PanoramaContainerVault>
  );
};

export default PanoramaDaily;
