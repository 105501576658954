import React, { useState } from "react";

import Img1 from "../../images/Gallery/845AB8C6-2BE3-4498-BDB4-1765807506EA.jpeg";
import Img2 from "../../images/Gallery/22780025-8168-4392-B5AE-E224D044FE44.jpeg";
import Img3 from "../../images/Gallery/E3030436-C174-4D6D-9DB7-9C9B6895B014.jpeg";
import Img4 from "../../images/Gallery/F2465F6D-BEA1-438F-8569-7666CBC418A3.jpeg";
import Img5 from "../../images/Gallery/6AEC0A8B-6E58-412D-BC0A-B0E8CAF4B88E.jpeg";
import Img6 from "../../images/Gallery/8A1C9874-F7B1-464E-8F95-CDB0C8605CF6.jpeg";
import Img7 from "../../images/Gallery/3EA77F49-384E-4B72-96BC-F273DBF95310.jpeg";
import Img8 from "../../images/Gallery/F247B939-CC58-4FE1-802B-7EB15F775182.jpeg";
import Img9 from "../../images/Gallery/8A1C9874-F7B1-464E-8F95-CDB0C8605CF6.jpeg";
import {
  CloseIcon,
  GalleryContainer,
  GalleryWrapper,
  Model,
  Pictures,
} from "./styledGallery";

const Gallery = () => {
  const data = [
    {
      id: 1,
      image: Img1,
    },
    {
      id: 2,
      image: Img2,
    },
    {
      id: 3,
      image: Img3,
    },
    {
      id: 4,
      image: Img4,
    },
    {
      id: 5,
      image: Img5,
    },
    {
      id: 6,
      image: Img6,
    },
    {
      id: 7,
      image: Img7,
    },
    {
      id: 8,
      image: Img8,
    },
    {
      id: 9,
      image: Img9,
    },
  ];
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (image) => {
    setTempImgSrc(image);
    setModel(true);
  };

  return (
    <GalleryContainer id="gallery">
      <Model model={model} onClick={() => setModel(false)}>
        <img src={tempImgSrc} alt="" />
        <CloseIcon onClick={() => setModel(false)} />
      </Model>
      <GalleryWrapper>
        {data.map((item, index) => {
          return (
            <Pictures key={index} onClick={() => getImg(item.image)}>
              <img src={item.image} alt="" style={{ width: "100%" }}></img>
            </Pictures>
          );
        })}
      </GalleryWrapper>
    </GalleryContainer>
  );
};

export default Gallery;
