import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { darkRed, green, red, white } from "../../../Theme";

import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1.8vw;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.5rem;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1500px;
`;

export const NavLogo = styled(LinkRouter)`
  color: ${white};
  display: flex;
  justify-self: flex-start;
  cursor: pointer;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  transition: opacity 0.3s;
  opacity: ${({ visibleLogo }) => (visibleLogo ? "1" : "0")};

  img {
    width: 75px;
    height: auto;
  }
`;

export const NavLogoRight = styled(NavLogo)`
  @media screen and (max-width: 1024px) {
    visibility: hidden;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${white};
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;

  /*Animated underline*/

  &:after {
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: ${darkRed};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover:after {
    width: 100%;
    left: 0;
  }
`;

export const NavLinks = styled(LinkScroll)`
  color: ${white};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid ${darkRed};
  }

  /*Just under line*/
  // &:hover {
  // 	border-bottom: 3px solid ${green};
  // 	color: ${green};
  // }
`;

export const BurgerMenu = styled(FaBars)`
  &:hover {
    color: ${red} !important;
  }
`;
