import React from "react";
import Coaches from "./Coaches";
import { CoeachesContainer, ContainerWrapper } from "../styledCoaches";

import Hrdlicka from "../../../images/Jarda Hrdlička.png";
import Klouda from "../../../images/Jan Klouda.png";
import PanoramaVault from "../../Panorama/panoramaVault";

const dataCoaches = () => {
  const coaches = [
    {
      id: 7,
      name: "Jaroslav Hrdlička",
      role: "Trenér MMA",
      role2: "Team B",
      photo: Hrdlicka,
      paragraph1:
        "Bojovým sportům se naplno věnuje 7 let. Dříve závodil ve sportovní gymnastice a atletice. V RPG má na starost &quot;B&quot; tým. Je dvojnásobným mistrem České republiky asociace ČSMMA a vítěz IMMAF Pan American Open Championships.",
      paragraph2:
        "Je trenérem MMA trenér III. třídy a absolventem bakalářského oboru na UK FTVS. Mezi jeho trenérské úspěchy se řadí 2x titul vicemistra ČR a titul mistra ČR.",
      phone: "727 970 409",
      textPosition: "center",
    },
    {
      id: 8,
      name: "Jan Klouda",
      role: "Trenér MMA",
      role2: "Team B",
      photo: Klouda,
      paragraph1:
        "Prošel Brazilským jiu-jitsu, boxem a MMA. V RPG má na starost „B“ tým. Získal titul mistra České republiky asociace ČSMMA 2018.",
      paragraph2:
        "Získal modrý pás v Brazilském jiu-jitsu. Je trenérem MMA III. třídy. Mezi jeho trenérské úspěchy patří 2x titul mistra ČR. Aktivně jezdí vypomáhat protipytláckým hlídkám ve světě.",
      textPosition: "center",
    },
  ];

  const coachList = coaches.map(
    (coach) => <Coaches coach={coach} hiddenDesc={coach?.hiddenDesc} /> //? - či ta propsa existuje, jinak je to je undefined
  );

  return (
    <CoeachesContainer id="coaches">
      <PanoramaVault />
      <ContainerWrapper>{coachList}</ContainerWrapper>
    </CoeachesContainer>
  );
};

export default dataCoaches;
