import styled from "styled-components";
import Wallpaper from "../../../images/RPG_Wallpaper.jpg";

export const HeaderOneContainer = styled.div`
  background-size: cover;
  background-position: top;
  position: relative;
  height: 500px;
  background-image: url(${Wallpaper});
`;

export const HeaderWrapper = styled.h1`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  img {
    width: 300px;
    height: auto;
  }
`;

export const HeaderHeading = styled.h1``;

export const HeaderP = styled.h1``;
