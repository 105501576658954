import React, { useState } from "react";
import Sidebar from "../Header/Sidebar/Sidebar";
import Navbar from "../Header/Navbar/Navbar";
import Sponsors from "../Sponsors/Sponsors";
import Wallpaper from "../Header/Wallpaper/Wallpaper";
import About from "../About/About";
import DataCoachesSterboholy from "../Gym/Sterboholy/dataCoachesSterboholy";
import DataCoachesModrany from "../Gym/Modrany/dataCoachesModrany";
import DataAthletes from "../Athletes/dataAthletes";
import TimeTable from "../TimeTable/timeTable";
import Price from "../Price/Price";
import Gallery from "../Gallery/Gallery";
import Contact from "../Contact/Contact";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      {/*/!*<HeroSection/>*!/*/}

      <Wallpaper />
      <About />
      <DataCoachesSterboholy />
      <DataCoachesModrany />
      <DataAthletes />
      <TimeTable />
      <Price />
      <Gallery />
      <Contact />
      <Sponsors />
    </div>
  );
};

export default Home;
