import styled from 'styled-components';
import { blackLight, red, white } from '../../Theme';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${blackLight};
  color: ${white};

  h2 {
    position: relative;
    text-align: center;
    color: ${white};
    margin-bottom: 16px;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }

    /*underline*/

    &:after {
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      background: ${red};
      width: 90%;
      left: 5%;
    }
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10%;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const PriceList = styled.div`
  margin: 10px 0;
  text-align: center;
  flex-wrap: wrap;
`;

export const Paragraph1 = styled.div`
  margin-bottom: 7px;
`;

export const Paragraph2 = styled.div`
  margin-bottom: 7px;
`;

export const Paragraph3 = styled.div`
  margin-bottom: 7px;
`;

export const UnypArenaPrices = styled.div`
  display: flex;
  gap: 20px;
`;

export const UnypArenaPricesUnderTitle = styled.div``;

export const UnypArenaPricesKids = styled.div`
  flex-direction: row;
`;

export const UnypArenaPricesAdults = styled.div`
  flex-direction: column;
`;
