import React from "react";
import Athletes from "./Athletes";
import { HeadingH2 } from "../../Theme/Headings";
import {
  AthleteContainer,
  AthleteMainP,
  AthleteWrapper,
  CenterWrapper,
} from "./styledAthletes";

import Juracek from "../../images/Matůš Juráček.png";
import Hosek from "../../images/David Hošek.png";
import Orlov from "../../images/Yevhenii Orlov.png";
import Mikulasek from "../../images/Václav Mikulášek.png";
import Broz from "../../images/Miroslav Brož.png";
import Mrakota from "../../images/Vít Mrákota.png";
import Kai from "../../images/Lu Kai.png";

const DataAthletes = () => {
  const athletes = [
    {
      id: 1,
      firstName: "David",
      nickname: '"SPARŤAN"',
      lastName: "Hošek",
      sport: "MMA, BOX",
      secondLineSport: "",
      facebook: "/",
      instagram: "/",
      photo: Hosek,
      button: "Zjistit více",
      popup: {
        heading: "David Hošek",
        nickname: '"SPARŤAN"',
        photo: Hosek,

        paragraph1: "2x Vícemistr ČR v boxu",
        paragraph2: "3x Vítěz mezinárodního turnaje v boxu",
        paragraph3: "Účastník Evropských her v Baku 2015",
        paragraph4: "Přes 8 zápasů v profesionálním MMA",
        tapologyLink:
          "https://www.tapology.com/fightcenter/fighters/185791-david-hosek",
        sherdogLink: "https://www.sherdog.com/fighter/David-Hosek-282605",
      },
    },
    {
      id: 2,
      firstName: "Matúš",
      nickname: '"SLÁVISTA"',
      lastName: "Juráček",
      sport: "MMA, BOX",
      secondLineSport: "",
      facebook: "/",
      instagram: "/",
      photo: Juracek,
      button: "Zjistit více",
      popup: {
        heading: "Matůš Juráček",
        nickname: '"SLÁVISTA"',
        photo: Juracek,

        paragraph1: "2x Amatérský mistr ČR v MMA.",
        paragraph2: "1x Amatérský mistr světa v MMA asociace WFMC",
        paragraph3: "Juniorský mistr ČR v K-1",
        paragraph4: "Vítěz prestižního boxerského turnaje Memorial Julia Tormy",
        paragraph5: "Přes 10 zápasů v profesionálním MMA",
        tapologyLink:
          "https://www.tapology.com/fightcenter/fighters/36771-matus-juracek",
        sherdogLink: "https://www.sherdog.com/fighter/Matus-Juracek-97527",
      },
    },
    {
      id: 3,
      firstName: "Yevhenii",
      nickname: "",
      lastName: "Orlov",
      sport: "MMA",
      secondLineSport: "ŘECKO-ŘÍMSKÝ ZÁPAS",
      facebook: "/",
      instagram: "/",
      photo: Orlov,
      button: "Zjistit více",
      popup: {
        heading: "Yevhenii Orlov",
        nickname: "",
        photo: Orlov,

        paragraph1: "Účastník olympijských her 2012 v Londýně",
        paragraph2: "Stříbrný medailista z mistrovství Evropy 2013",
        paragraph3: "Účastník Evropských her v Baku 2015",
        paragraph4: "Bronzový medailista z mistrovství Evropy 2012",
        paragraph5: "Mnohonásobný šampion Ukrajiny",
        tapologyLink:
          "https://www.tapology.com/fightcenter/fighters/188847-evgeny-orlov",
        sherdogLink: "https://www.sherdog.com/fighter/Yevhenii-Orlov-273409",
      },
    },
    {
      id: 4,
      firstName: "Vít",
      nickname: "",
      lastName: "Mrákota",
      sport: "MMA ",
      secondLineSport: "GRAPPLING",
      facebook: "/",
      instagram: "/",
      photo: Mrakota,
      button: "Zjistit více",
      popup: {
        heading: "Vít Mrákota",
        nickname: "",
        photo: Mrakota,

        paragraph1:
          "Prošel organizacema GCF, FFC, Oktagon, White Rex, Fight Night Global",
        paragraph2: "2x mistr v Grapplingu",
        paragraph3: "1x mistr Polska v Grapplingu -> Master",
        paragraph4: "Kolem 90 zápasů v Grapplingu",
        paragraph5:
          "Přes 200 zápasů ve Wrestlingu – několikrát na stupních vítězů",
        paragraph6: "Hnědý pás v Brazilském Jiu-Jitsu",
        tapologyLink:
          "https://www.tapology.com/fightcenter/fighters/34764-vita-mrakota",
        sherdogLink: "https://www.sherdog.com/fighter/Vit-Mrakota-58681",
      },
    },
    {
      id: 5,
      firstName: "Václav",
      nickname: '"BABA JAGA"',
      lastName: "Mikulášek",
      sport: "MMA",
      secondLineSport: "",
      facebook: "/",
      instagram: "/",
      photo: Mikulasek,
      button: "Zjistit více",
      popup: {
        heading: "Václav Mikulášek",
        nickname: '"BABA JAGA"',
        photo: Mikulasek,

        paragraph1: "V boxu 30 zápasů 24 výher 6 proher",
        paragraph2: "Druhý na amatérském mistrovství republiky do 93 kg v MMA",
        paragraph3: "Přes 16 profesionálních zápasů v MMA",
        paragraph4: "Neaktuální Sherdog stále chybí 2 zápasy",
        tapologyLink: "https://www.sherdog.com/fighter/Vaclav-Mikulasek-138447",
        sherdogLink:
          "https://www.tapology.com/fightcenter/fighters/116980-vaclav-mikulasek",
      },
    },
    {
      id: 6,
      firstName: "Miroslav",
      nickname: '"INKVIZITOR"',
      lastName: "Brož",
      sport: "MMA",
      secondLineSport: "",
      facebook: "/",
      instagram: "/",
      photo: Broz,
      button: "Zjistit více",
      popup: {
        heading: "Miroslav Brož",
        nickname: '"INKVIZITOR"',
        photo: Broz,

        paragraph1: "2x amaterský mistr republiky v MMA do 84 kg",
        paragraph2: "5. místo na amatérském mistrovstí světa v MMA",
        paragraph3: "Amatérský skóre 21 zápasu 18 vyher 2 prohry 1 remiza",
        tapologyLink:
          "https://www.tapology.com/fightcenter/fighters/123391-miroslav-broz",
        sherdogLink: "https://www.sherdog.com/fighter/Miroslav-Broz-244235",
      },
    },
    {
      id: 7,
      firstName: "Lu",
      nickname: '"LUKÁŠ"',
      lastName: "Kai",
      sport: "MMA",
      secondLineSport: "",
      facebook: "/",
      instagram: "/",
      photo: Kai,
      button: "Zjistit více",
      popup: {
        heading: "Lu Kai",
        nickname: '"LUKÁŠ"',
        photo: Kai,
        paragraph1:
          "Držitel černý pás v judu a v roce 2014 vyhrál mistrovství Číny v Judu (73 kg)",
        paragraph2:
          "V mistrovství v tradičním čínském wrestlingu ve Vnitřním Mongolsku vybojoval stříbro.",
        paragraph3:
          "MMA (8:3) se věnuje od roku 2016. Je držitelem pásů organizací CKF a ICKF v lehké váze.",
        paragraph4:
          "Zápasil také pod Road FC, IAF, RFA a v turnaji Road to UFC.",
        tapologyLink: "https://www.tapology.com/fightcenter/fighters/123937",
        sherdogLink: "https://www.sherdog.com/fighter/Kai-Lu-217317",
      },
    },
  ];

  return (
    <AthleteContainer id="athletes">
      <HeadingH2 size="3">Naši Atleti</HeadingH2>
      <AthleteMainP>
        Aktuálně nás na profesionální úrovni reprezentují tito atleti. RPG též
        reprezentuje mnoho atletů na amatérské úrovni.
      </AthleteMainP>
      <CenterWrapper>
        <AthleteWrapper>
          {athletes.map((athlete) => (
            <Athletes athlete={athlete} />
          ))}
        </AthleteWrapper>
      </CenterWrapper>
    </AthleteContainer>
  );
};

export default DataAthletes;
