import React from 'react';
import {
  Container,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  PriceList,
  PriceWrapper,
  UnypArenaPrices,
  UnypArenaPricesKids,
  UnypArenaPricesAdults,
  UnypArenaPricesUnderTitle,
} from './styledPrice';
import { HeadingH3, HeadingH2 } from '../../Theme/Headings';

const Price = () => {
  return (
    <Container id='price'>
      <HeadingH2>Ceník</HeadingH2>
      <PriceWrapper>
        <PriceList>
          <HeadingH3>UNYP ARENA</HeadingH3>
          <UnypArenaPrices>
            <UnypArenaPricesUnderTitle>Děti:</UnypArenaPricesUnderTitle>
            <UnypArenaPricesKids>
              <Paragraph1>Jednorázově: 150 Kč</Paragraph1>
              <Paragraph1>Permanentka 1000 Kč</Paragraph1>
              <Paragraph1>Multisportka NE</Paragraph1>
            </UnypArenaPricesKids>
            <UnypArenaPricesUnderTitle>Dospělí:</UnypArenaPricesUnderTitle>
            <UnypArenaPricesKids>
              <Paragraph1>Jednorázově: 200 Kč</Paragraph1>
              <Paragraph2>Permanentka 2000 Kč</Paragraph2>
              <Paragraph3>Multisportka NE</Paragraph3>
            </UnypArenaPricesKids>
          </UnypArenaPrices>
        </PriceList>
        <PriceList>
          <HeadingH3>Modřany</HeadingH3>
          <Paragraph1>Jednorázově: 160 Kč</Paragraph1>
          <Paragraph2>Permanentka Vault + 60 Kč</Paragraph2>
          <Paragraph3>Multisportka ANO</Paragraph3>
        </PriceList>
      </PriceWrapper>
    </Container>
  );
};

export default Price;
