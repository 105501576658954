import styled from "styled-components";

import { white, blackLight } from "../../Theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${blackLight};
  h2 {
    color: ${white};
    margin-bottom: 16px;
  }
`;

export const Info = styled.div`
  color: ${white};
  display: flex;
  flex-direction: row;
`;

export const Email = styled.div`
  color: ${white};
  margin: 5px 0 0 5px;
`;

export const MapLocation = styled.div`
  display: flex;
  color: white;
  margin-top: 40px;
  width: 100%;
  h3 {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 678px) {
    flex-direction: column;
  }
`;
export const MapSterboholy = styled.div`
  iframe {
    width: 90%;
  }
`;

export const MapModrany = styled.div`
  iframe {
    width: 90%;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  width: 50%;
  @media screen and (max-width: 678px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Address = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
