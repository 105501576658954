import React from "react";
import {
  PanoramaContainerUnyp,
  TitleWrapper,
  Title,
  SubTitle,
} from "./styledPanorama";

const PanoramaDaily = () => {
  return (
    <PanoramaContainerUnyp>
      <TitleWrapper>
        <Title>
          UNYP ARENA
          <SubTitle>Trenéři</SubTitle>
        </Title>
      </TitleWrapper>
    </PanoramaContainerUnyp>
  );
};

export default PanoramaDaily;
